<template>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">绩效数据管理</h2>
        <div>
          <el-form class="twoForm" :inline="true">
            <el-form-item label="开始时间：" class="timClas">
<!--              :picker-options="pickerOptionsEndequivalent"-->
              <el-date-picker
                  v-model="startTime"
                  type="month"
                  format="yyyy 年 MM 月"
                  value-format="yyyy-MM"
                  @change="changeTime"
                  placeholder="选择开始时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间：" class="timClas">
              <el-date-picker
                  v-model="endTime"
                  type="month"
                  format="yyyy 年 MM 月"
                  value-format="yyyy-MM"
                  @change="changeTime"
                  placeholder="选择结束时间">
              </el-date-picker>
            </el-form-item>
            <!--绩效办显示-->
            <el-form-item label="科室：" v-if="departmentName=='绩效办'||deptId=='101'">
              <el-select v-model="typeValue" placeholder="请选择" clearable filterable  @change="typeValueChange(typeValue)" class="xiangType">
                <el-option v-for="item in depoptions" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <div>
              <el-button style="width: 120px;" type="primary" v-if="cesuanClick" @click="downBusinessExcel" :loading="cesuanClickTwo">导出数据</el-button>
              <el-button v-else type="info" :loading="cesuanClickTwo"></el-button>
            </div>
          </el-form>
        </div>
        <el-table
            :data="DeplisttableData"
            border
            center
        >
          <el-table-column type="index" label="序号" width="55" align="center" :index="indexMethod">
          </el-table-column>
          <el-table-column
              prop="basePeriodTime"
              label="时间"
              align="center"
          >
          </el-table-column>
          <el-table-column prop="name" label="科室" align="center">
          </el-table-column>
          <el-table-column prop="workload" label="工作量绩效" align="center" width="100">
          </el-table-column>
          <el-table-column prop="outCheckWorkload" label="外院化验绩效" align="center">
          </el-table-column>
          <el-table-column prop="checkup" label="体检专项" align="center">
          </el-table-column>
          <el-table-column prop="cancerScreen" label="两癌筛查" align="center">
          </el-table-column>
          <el-table-column prop="medicalInsuranceFund" label="医保基金奖励" align="center">
          </el-table-column>
          <el-table-column prop="highQualityCare" label="优质护理奖励" align="center">
          </el-table-column>
          <el-table-column prop="qualityControl" label="质控奖罚" align="center">
          </el-table-column>
          <el-table-column prop="bonusSubsidy" label="奖金补助" align="center">
          </el-table-column>
          <el-table-column prop="otherSpecial" label="体检专项(体检科)" align="center">
          </el-table-column>
          <el-table-column prop="painfulDelivery" label="阵痛分娩奖励" align="center">
          </el-table-column>
          <el-table-column prop="other" label="其他" align="center">
          </el-table-column>
          <el-table-column prop="nightDutyAllowance" label="夜班费" align="center">
          </el-table-column>
          <el-table-column prop="personnelExpenditure" label="人员列支" align="center">
          </el-table-column>
          <el-table-column prop="indexScore" label="考核得分" align="center">
          </el-table-column>
          <el-table-column prop="payableAmount" label="应发金额" align="center">
          </el-table-column>
          <el-table-column prop="actualAmount" label="实发金额" align="center">
          </el-table-column>
          <el-table-column prop="numOfPersonnel" label="在岗人数" align="center">
          </el-table-column>
          <el-table-column prop="averageBonus" label="科室平均奖金" align="center">
          </el-table-column>
        </el-table>
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="total"
        >
        </el-pagination>
      </el-main>
    </el-container>
</template>

<script>
import _qs from "qs";

export default {
  data() {
    return {
      // 只选择自己想选的年份和月份
      creactMonthArr:['2022-05','2022-08'],
      pickerOptionsEndequivalent: {
        disabledDate: (time) => {
          // console.log(time)
          const year = time.getFullYear()
          let month = time.getMonth() + 1
          if (month < 10) month = '0' + month
          const ym = year + '-' + month
          return !this.creactMonthArr.includes(ym)
        },
      },
      // 导出按钮置灰
      cesuanClick: true,
      cesuanClickTwo: false,
      depoptions:[],
      typeValue:"",
      startTime:'',
      endTime:"",
      // 表格数据
      DeplisttableData: [],
      // 医院id
      hospitalId: window.sessionStorage.getItem("hospitalId"),
      // 总页数
      total: 1,
      // 每页页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
      departmentName:window.sessionStorage.getItem('departmentName'),
      deptId:window.sessionStorage.getItem("deptId")
    };
  },
  created() {
    this.getDateTime()
  },
  methods: {
    async getDateTime(){
      let { data: res } = await this.$axios.getDateTime();
      // console.log(res);
      this.startTime=res
      this.endTime=res

      this.getDepartmentNotPage();
      // 科室数据查询
      this.findDepartment()
    },
    typeValueChange(val){
      this.typeValue=val
      this.getDepartmentNotPage();
    },
    async findDepartment(){
      let data = {
        hospitalId:window.sessionStorage.getItem("hospitalId")
      };
      let { data: res } = await this.$axios.findDepartment(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        this.depoptions = res.data;
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    indexMethod(index) {
      let pageNum = this.pageNum; // 当前页码
      let pageSize = this.pageSize; // 每页条数
      return index + 1 + (pageNum - 1) * pageSize; // 返回表格序号
    },
    changeTime(){
      this.getDepartmentNotPage();
    },
    // 点击导出数据
    async downBusinessExcel() {
      this.cesuanClick = false;
      this.cesuanClickTwo = true;
      let data = {
        basePeriodTime:this.startTime,
        date:this.endTime,
        pageType:'LIST',//绩效数据管理
      };

      if(window.sessionStorage.getItem("departmentName")=='绩效办'||window.sessionStorage.getItem("deptId")=='101'){
        data.departmentId=this.typeValue
      }else{
        data.departmentId=window.sessionStorage.getItem("departmentId")
      }

      let res = await this.$axios.perexport(data);
      // console.log(res);
      if (res.data.code == 401) {
        this.$router.push("/login");
      } else if (res.data.code == 500) {
        this.$message({
          message: "导出失败",
          type: "error",
        });
      } else if (res.status == 200) {
        let updateTime = this.addDate();
        let blob = new Blob([res.data], { type: "application/x-xls" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        //配置下载的文件名
        link.download =updateTime+"绩效数据管理" + ".xls";
        link.click();
        this.cesuanClick = true;
        this.cesuanClickTwo = false;
      }
    },
    addDate() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
      };
      const newmonth = date.month >= 10 ? date.month : "0" + date.month;
      const day = date.date >= 10 ? date.date : "0" + date.date;
      let updateTime = date.year + "-" + newmonth + "-" + day;
      return updateTime;
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.getDepartmentNotPage();
    },
    //   绩效数据管理列表
    async getDepartmentNotPage() {
      let data = {
        basePeriodTime:this.startTime,//起始时间
        date:this.endTime,//结束时间
        pageNum: this.pageNum, //页数
        pageSize: this.pageSize, //每页显示数量
        pageType:'LIST',//绩效数据管理
      };
      // 只有科室管理员需要传科室id
      if(window.sessionStorage.getItem("departmentName")=='绩效办'||window.sessionStorage.getItem("deptId")=='101'){
        data.departmentId=this.typeValue
      }else{
        data.departmentId=window.sessionStorage.getItem("departmentId")
      }

      let { data: res } = await this.$axios.findCurrentYearAndMonthInformation(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      }else if (res.code == 200) {
        this.DeplisttableData = res.rows;
        this.total = res.total;
      } else{
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tabBig{
  width: 500px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-around;
  /deep/ .el-input__inner{
    border:none!important;
  }
  .line{
    height: 40px;
    border: 1px solid #ccc;
  }
  .boxTwo,.boxOne{
    width: 50%;
  }
}

/deep/ .el-form-item.hosform{
  margin-bottom: 0px!important;
}

.twoForm {
  float: right;
  display: flex;
}

/deep/ .el-select.xiangType .el-input--suffix {
  width: 150px !important;
}

.activeStatus {
  background: rgb(102, 204, 0);
}
.noStatus,
.activeStatus {
  width: 25px;
  height: 25px;
  color: white;
  line-height: 25px;
  text-align: center;
  margin: 0 auto;
}

.noStatus {
  background: #ccc;
}
.depTitle {
  display: flex;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}

// 取消开始时间和结束时间的×符号
/deep/ .twoForm .timClas .el-input__suffix{
  display: none!important;
}
</style>
